import React from 'react'
import Layout from '../components/layout'

import bg from '../images/eat_fit_abq_about.png'

const Contact = () => (
  <Layout>
    <img
      style={{ position: 'absolute', right: 10, top: 10 }}
      src={bg}
      alt="background"
    />
    <div
      className="header"
      style={{
        position: 'absolute',
        left: 250,
        top: 40,
      }}
    >
      About Us
    </div>
    <div
      style={{
        width: 315,
        fontFamily: 'Verdana, Geneva, sans-serif',
        fontSize: 14,
        position: 'absolute',
        top: 83,
        left: 250,
      }}
    >
      <p>
        <span className="greentext">Eat Fit</span> was created out of need by
        professional fighter Nohime Dennisson in order to get to her fighting
        weight without depriving herself of the healthy, great tasting food that
        she loves.
        <br />
        <br />
        The <span className="greentext">Eat Fit</span> program features many
        varieties of tasty, fresh and healthy menu items. Simply choose from a
        diverse menu updated weekly and get it delivered right to your home or
        office. Call or email us for complete details on how to start your
        custom program.
      </p>
    </div>
  </Layout>
)

export default Contact
